<style lang="scss"></style>

<template>
  <div id="editmitarbeiter">
    <div class="row">
      <div class="col-12">
        <h1 class="headline">Neuen Mitarbeiter hinzufügen</h1>
      </div>
    </div>
    <MitarbeiterForm new :person="{ nameFirst: '', nameLast: '', role: 0, login: '', email: '', location: '', team: '', avatar: '' }" :saving="saving" />
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { provide, Ref, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MitarbeiterForm from './MitarbeiterForm.vue';

export default defineComponent({
  components: {
    MitarbeiterForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const saving = ref(false);

    provide('onSave', (person: Ref<User>) => {
      saving.value = true;
      const formData = new FormData();

      formData.append('nameFirst', person.value.nameFirst);
      formData.append('nameLast', person.value.nameLast);
      formData.append('role', person.value.role.toString());
      formData.append('login', person.value.login);
      formData.append('email', person.value.email);
      if (person.value.position) formData.append('position', person.value.position);
      formData.append('location', person.value.location);
      formData.append('team', person.value.team);
      formData.append('invisible', person.value.invisible?.toString() || 'false');
      if (person.value.password) formData.append('password', person.value.password);
      if (typeof person.value.avatar !== 'string') formData.append('avatar', person.value.avatar || '');

      store.dispatch('API', { method: 'post', url: '/manage/newuser', data: formData }).then((res) => {
        saving.value = false;
        store.dispatch('addNotification', {
          type: 'success',
          lifetime: 4000,
          message: 'Mitarbeiter angelegt',
        });

        router.push({ name: 'Mitarbeiter verwalten' });
      });
    });

    return {
      saving,
    };
  },
});
</script>
